<template>
  <fetch-json url="/hatching-systems" :filter="filter">
    <template v-slot:header>
      <div class="flex justify-between mb-4">
        <h1 class="text-2xl font-bold">Hatching systems</h1>
        <primary-button @click="showHatchingSystemDialog = true">
          + Add New
        </primary-button>
      </div>
    </template>
    <template v-slot:controls>
      <select-field
        v-model="filter.status"
        label="Status"
        :reduce="option => option.value"
        :fixed-options="statuses"
        :move-label="false"
        class="w-64 shadow-md bg-white rounded"
      />
    </template>
    <template
      slot-scope="{ response: hatchingSystems, reload, deleteResource }"
    >
      <hatching-system-list-item
        v-for="hatchingSystem in hatchingSystems"
        :key="hatchingSystem.id"
        :hatching-system="hatchingSystem"
        class="mb-6"
        @edit="onEdit(hatchingSystem)"
        @deactivate="onDeactivate(hatchingSystem)"
        @assign-cart="onAssignCart(hatchingSystem)"
        @detect-cart="onDetectCart(hatchingSystem)"
      />

      <hatching-system-form
        :show="showHatchingSystemDialog"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :hatching-system="selectedHatchingSystem"
      />

      <assign-cart
        :show="showAssignCart"
        :hatching-system="selectedHatchingSystem"
        @assign="reload"
        @close="onClose"
      />

      <confirm-action-dialog
        :show="showDeactivationDialog"
        type="delete"
        :resource="selectedHatchingSystem"
        :action="deleteResource"
        @done="reload"
        @close="onClose"
      >
        <template slot="title">Deactivate Hatching System</template>
        <p class="mb-4">
          Are you sure you want to deactivate the Hatching System?
        </p>
        <p v-if="selectedHatchingSystem">
          Hatching System Id: {{ selectedHatchingSystem.id }}
        </p>
      </confirm-action-dialog>
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import HatchingSystemListItem from '@/components/hatching-systems/HatchingSystemListItem';
import HatchingSystemForm from '@/components/hatching-systems/HatchingSystemForm';
import AssignCart from '@/components/hatching-systems/AssignCart';
import { handleFailedResponse } from '@/core/helpers';

export default {
  name: 'HatchingSystems',
  components: {
    AssignCart,
    HatchingSystemForm,
    HatchingSystemListItem,
    FetchJson
  },
  data() {
    return {
      showHatchingSystemDialog: false,
      showDeactivationDialog: false,
      selectedHatchingSystem: null,
      showAssignCart: false,
      statuses: [
        { name: 'All system', value: null },
        { name: 'Active', value: 'active' },
        { name: 'Inactive', value: 'inactive' }
      ],
      filter: {
        status: 'active'
      }
    };
  },
  methods: {
    onClose() {
      this.selectedHatchingSystem = null;
      this.showHatchingSystemDialog = false;
      this.showDeactivationDialog = false;
      this.showAssignCart = false;
    },
    onEdit(hatchingSystem) {
      this.selectedHatchingSystem = hatchingSystem;
      this.showHatchingSystemDialog = true;
    },
    onAssignCart(hatchingSystem) {
      this.selectedHatchingSystem = hatchingSystem;
      this.showAssignCart = true;
    },
    onDeactivate(hatchingSystem) {
      this.selectedHatchingSystem = hatchingSystem;
      this.showDeactivationDialog = true;
    },
    async onDetectCart(hatchingSystem) {
      try {
        await this.axios.post(`/carts/${hatchingSystem.cart.id}/detect`);
        this.$notify({ group: 'app', text: 'Event sent.' });
      } catch (e) {
        handleFailedResponse(e);
      }
    }
  }
};
</script>
