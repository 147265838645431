<template>
  <modal-dialog :show="show" @close="close" width="400">
    <div>
      <h1 class="text-center text-2xl font-bold mb-4">
        Sensor
      </h1>
      <input-field
        v-model="form.name"
        label="Name"
        class="mb-6"
        :error="form.errors.get('name')"
      />
      <div class="text-center">
        <primary-button
          :loading="processing"
          class="w-full mb-4"
          @click="submit"
        >
          Submit
        </primary-button>
        <button @click="close" class="px-2 py-1">
          Close
        </button>
      </div>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    location: {
      type: Object,
      required: true
    },
    sensor: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        name: null
      })
    };
  },
  watch: {
    sensor: {
      immediate: true,
      handler(value) {
        if (value === null) {
          this.form.reset();
          return;
        }

        this.form.name = value.name;
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.sensor ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        const { data: sensor } = await this.form.submit(
          'post',
          `/locations/${this.location.id}/sensors`
        );

        this.$emit('create', { sensor });
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit(
          'put',
          `/locations/${this.location.id}/sensors/${this.sensor.id}`
        );

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
