<template>
  <modal-dialog :show="show" @close="onClose" width="600">
    <div class="flex justify-between">
      <div>
        <h1 class="font-bold text-lg">Configure Alerts</h1>
        <div>{{ location.name }} (#{{ location.id }})</div>
      </div>
      <div>
        <button @click="$emit('close')" class="p-2">
          <fa-icon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>

    <fetch-json url="/users" include="locations">
      <template slot-scope="{ response: users }">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="p-2 text-left">Name</th>
              <th class="p-2 text-left">Email</th>
              <th class="p-2 text-right">Alert</th>
            </tr>
          </thead>
          <tbody>
            <user-alert-list-item
              v-for="user in users"
              :key="user.id"
              :user="user"
              :location="location"
            />
          </tbody>
        </table>
      </template>
    </fetch-json>
  </modal-dialog>
</template>

<script>
import FetchJson from '../global/FetchJson';
import UserAlertListItem from './UserAlertListItem';
export default {
  components: { UserAlertListItem, FetchJson },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      users: []
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>

<style>
.modebar-group {
  display: flex !important;
}
</style>
