<template>
  <modal-dialog :show="show" @close="onClose" full-width>
    <sensor-reading-chart :location="location" @close="onClose" />
  </modal-dialog>
</template>

<script>
import SensorReadingChart from './SensorReadingChart';

export default {
  components: { SensorReadingChart },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClose() {
      this.token = null;
      this.$emit('close');
    }
  }
};
</script>
