<template>
  <div class="bg-white rounded-lg shadow-md overflow-hidden">
    <div class="flex justify-between items-center leading-none p-4 border-b">
      <div class="flex items-center">
        <div class="text-xl mr-4">#{{ hatchingSystem.id }}</div>
        <span
          v-if="!hatchingSystem.is_active"
          class="bg-red-200 text-red-700 rounded text-xs font-bold px-2 py-1"
        >
          Inactive
        </span>
      </div>
      <div>
        <button
          v-if="hatchingSystem.is_active"
          class="text-xs text-red-700 px-2 py-1 border rounded font-bold shadow mr-4"
          @click="$emit('deactivate')"
        >
          deactivate
        </button>
        <button
          v-if="hatchingSystem.is_active"
          class="text-xs text-gray-700 px-2 py-1 border rounded font-bold shadow"
          @click="$emit('edit')"
        >
          edit
        </button>
      </div>
    </div>
    <div class="p-4 overflow-hidden">
      <div class="flex mb-2">
        <spec-label
          class="w-1/4"
          label="Cart start position (cm)"
          :value="hatchingSystem.start_position"
        />
        <spec-label
          class="w-1/4"
          label="No. of boxes"
          :value="hatchingSystem.no_of_feeding_boxes"
        />
        <spec-label
          class="w-1/4"
          label="First feeding box position (cm)"
          :value="hatchingSystem.first_feeding_box_position"
        />
        <spec-label
          class="w-1/4"
          label="Box spacing (cm)"
          :value="hatchingSystem.feeding_box_spacing"
        />
      </div>
    </div>
    <div class="flex justify-between px-4 py-2 bg-gray-100 border-t">
      <div>
        <span v-if="cart" class="text-sm font-bold">
          Assigned cart: #{{ cart.id }}
          <span v-if="cart.name">({{ cart.name }})</span>
        </span>
        <span v-else class="text-sm text-red-500 font-bold">
          No cart assigned.
        </span>
      </div>

      <div>
        <button
          v-if="cart"
          class="text-xs text-gray-700 px-2 py-1 border rounded font-bold bg-white shadow"
          @click="$emit('detect-cart')"
        >
          detect
        </button>
        <button
          v-if="hatchingSystem.is_active"
          class="text-xs text-gray-700 px-2 py-1 border rounded font-bold bg-white shadow ml-2"
          @click="$emit('assign-cart')"
        >
          {{ cart ? 'change' : 'assign' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SpecLabel from '@/components/global/SpecLabel';

export default {
  components: { SpecLabel },
  props: {
    hatchingSystem: {
      type: Object,
      required: true
    }
  },
  computed: {
    cart() {
      return this.hatchingSystem.cart;
    }
  }
};
</script>
