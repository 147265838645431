<template>
  <fetch-json url="/locations" disable-pagination>
    <template v-slot:header>
      <div class="flex justify-between mb-4">
        <section-title>Locations</section-title>
        <primary-button @click="showForm = true">
          + Add New
        </primary-button>
      </div>
    </template>
    <template slot-scope="{ response: locations, deleteResource, reload }">
      <breeding-location-list-item
        v-for="location in locations"
        :key="location.id"
        :location="location"
        class="mb-4"
        @edit="onEdit(location)"
        @delete="onDelete(location)"
        @add-sensor="addSensor"
        @regenerate-token="onRegenerateToken"
        :edit-sensor="editSensor"
        :delete-sensor="deleteSensor"
        @open-graph="openGraph"
        @show-alerts="showAlerts"
      />

      <location-form
        :show="showForm"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :location="selectedLocation"
      />

      <sensor-form
        v-if="selectedLocation"
        :show="showSensorForm"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :location="selectedLocation"
        :sensor="selectedSensor"
      />

      <confirm-action-dialog
        :show="showDeleteDialog"
        type="delete"
        width="600"
        :resource="selectedLocation"
        :action="deleteResource"
        @close="onClose"
        @done="reload"
      >
        <template slot="title">
          Delete location
          <span v-if="selectedLocation">
            &nbsp; {{ selectedLocation.name }} ({{ selectedLocation.type }})
          </span>
          ?
        </template>
        <p>
          Deleting a location will also delete all its sensors information. Are
          you sure you want to delete selected location?
        </p>
      </confirm-action-dialog>

      <confirm-action-dialog
        v-if="selectedLocation && selectedSensor"
        :show="showDeleteSensorDialog"
        type="delete"
        width="600"
        :resource="selectedSensor"
        :action="onDeleteSensor"
        @close="onClose"
        @done="reload"
      >
        <template slot="title">
          Delete location
          <span v-if="selectedLocation">
            &nbsp; #{{ selectedSensor.id }} {{ selectedSensor.name }}
          </span>
          ?
        </template>
        <p>
          Are you sure you want to delete selected sensor?
        </p>
      </confirm-action-dialog>

      <regenerate-sensor-token
        v-if="selectedLocation && selectedSensor"
        :show="showRegenerateTokenDialog"
        :location="selectedLocation"
        :sensor="selectedSensor"
        @close="onClose"
      />

      <sensor-readings-chart-dialog
        v-if="selectedLocation && showLocationChart"
        :show="showLocationChart"
        :location="selectedLocation"
        @close="onClose"
      />
      <location-alert-dialog
        v-if="selectedLocation && showAlertsDialog"
        :show="showAlertsDialog"
        :location="selectedLocation"
        @close="onClose"
      />
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import BreedingLocationListItem from '@/components/climate-control/BreedingLocationListItem';
import LocationForm from '@/components/climate-control/LocationForm';
import SensorForm from '@/components/climate-control/SensorForm';
import RegenerateSensorToken from '@/components/climate-control/RegenerateSensorToken';
import LocationAlertDialog from '@/components/climate-control/LocationAlertDialog';
import SensorReadingsChartDialog from '@/components/climate-control/SensorReadingsChartDialog';

export default {
  name: 'ClimateControl',
  components: {
    SensorReadingsChartDialog,
    LocationAlertDialog,
    RegenerateSensorToken,
    SensorForm,
    LocationForm,
    BreedingLocationListItem,
    FetchJson
  },
  data() {
    return {
      showForm: false,
      showSensorForm: false,
      showLocationChart: false,
      showAlertsDialog: false,
      selectedLocation: null,
      selectedSensor: null,
      showDeleteDialog: false,
      showDeleteSensorDialog: false,
      showRegenerateTokenDialog: false
    };
  },
  methods: {
    onClose() {
      this.selectedLocation = null;
      this.showForm = false;
      this.showLocationChart = false;
      this.showSensorForm = false;
      this.showDeleteDialog = false;
      this.showDeleteSensorDialog = false;
      this.showRegenerateTokenDialog = false;
      this.showAlertsDialog = false;
    },
    onEdit(location) {
      this.selectedLocation = location;
      this.showForm = true;
    },
    onDelete(location) {
      this.selectedLocation = location;
      this.showDeleteDialog = true;
    },
    onRegenerateToken({ location, sensor }) {
      this.selectedLocation = location;
      this.selectedSensor = sensor;
      this.showRegenerateTokenDialog = true;
    },
    addSensor(location) {
      this.selectedLocation = location;
      this.selectedSensor = null;
      this.showSensorForm = true;
    },
    editSensor(location, sensor) {
      this.selectedLocation = location;
      this.selectedSensor = sensor;
      this.showSensorForm = true;
    },
    deleteSensor(location, sensor) {
      this.selectedLocation = location;
      this.selectedSensor = sensor;
      this.showDeleteSensorDialog = true;
    },
    onDeleteSensor() {
      return this.axios.delete(
        `/locations/${this.selectedLocation.id}/sensors/${this.selectedSensor.id}`
      );
    },
    openGraph(location) {
      this.selectedLocation = location;
      this.showLocationChart = true;
    },
    showAlerts(location) {
      this.selectedLocation = location;
      this.showAlertsDialog = true;
    }
  }
};
</script>
