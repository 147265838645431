<template>
  <fetch-json url="/feeding-box-dimensions">
    <template v-slot:header>
      <div class="flex justify-between">
        <section-title>Feeding box dimensions</section-title>
        <primary-button @click="showForm = true">
          + Add New
        </primary-button>
      </div>
    </template>
    <template slot-scope="{ response: dimensions, reload, deleteResource }">
      <table class="table-auto bg-white w-full rounded-lg shadow-md">
        <thead>
          <tr>
            <th class="text-left border px-4 py-2">Id</th>
            <th class="text-left border px-4 py-2">Name</th>
            <th class="text-right border px-4 py-2">Length (cm)</th>
            <th class="text-right border px-4 py-2">Width (cm)</th>
            <th class="text-right border px-4 py-2">Height (cm)</th>
            <th class="text-right border px-4 py-2">No of larvae</th>
            <th class="text-right border px-4 py-2">Created At</th>
            <th class="text-right border px-4 py-2">Updated At</th>
            <th class="text-right border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <feeding-box-dimension-list-item
            v-for="dimension in dimensions"
            :key="dimension.id"
            :feeding-box-dimension="dimension"
            class="mb-4"
            @edit="onEdit(dimension)"
            @delete="onDelete(dimension)"
          />
        </tbody>
      </table>

      <feeding-box-dimension-form
        :show="showForm"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :feeding-box-dimension="selectedDimension"
      />

      <confirm-action-dialog
        :show="showDeleteDialog"
        type="delete"
        :resource="selectedDimension"
        :action="deleteResource"
        @close="onClose"
        @done="reload"
      >
        <template slot="title">Delete feeding box dimension?</template>
        <p>Are you sure you want to delete selected feeding box dimension?</p>
      </confirm-action-dialog>
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import FeedingBoxDimensionListItem from '@/components/feeding-box-dimensions/FeedingBoxDimensionListItem';
import FeedingBoxDimensionForm from '@/components/feeding-box-dimensions/FeedingBoxDimensionForm';

export default {
  name: 'FeedingBoxDimensions',
  components: {
    FeedingBoxDimensionForm,
    FeedingBoxDimensionListItem,
    FetchJson
  },
  data() {
    return {
      showForm: false,
      selectedDimension: null,
      showDeleteDialog: false
    };
  },
  methods: {
    onClose() {
      this.selectedDimension = null;
      this.showForm = false;
      this.showDeleteDialog = false;
    },
    onEdit(dimension) {
      this.selectedDimension = dimension;
      this.showForm = true;
    },
    onDelete(dimension) {
      this.selectedDimension = dimension;
      this.showDeleteDialog = true;
    }
  }
};
</script>
