<template>
  <modal-dialog :show="show" @close="onClose">
    <cart-form
      v-if="!token"
      @close="onClose"
      @create="onCreate"
      @edit="$emit('edit')"
      :cart="cart"
    />
    <div v-else class="text-center">
      <h3 class="font-bold mb-4">Configure Cart #{{ createdCart.id }}</h3>
      <div
        class="relative text-sm bg-primary-100 border border-green-200 rounded py-2 px-4 mb-4"
      >
        {{ token }}
        <div
          class="absolute left-0 top-0 w-full font-bold text-white flex items-center justify-center bg-primary-500 transition-all duration-300"
          :class="copied ? 'h-full opacity-100' : 'h-0 opacity-0'"
        >
          Copied!
        </div>
      </div>
      <button
        @click="copy"
        class="border rounded px-4 py-2 mb-4 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
      >
        copy
      </button>
      <br />
      <button @click="onClose">close</button>
    </div>
  </modal-dialog>
</template>

<script>
import CartForm from '@/components/carts/CartForm';

export default {
  components: { CartForm },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    cart: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      createdCart: null,
      token: null,
      copied: false
    };
  },
  methods: {
    onCreate({ cart, token }) {
      this.createdCart = cart;
      this.token = token;
    },
    onClose() {
      const created = !!this.token;
      this.show = false;
      this.token = false;
      this.createdCart = null;
      this.$emit('close');
      if (created) {
        this.$emit('create');
      }
    },
    copy() {
      if (this.copied) {
        return;
      }
      this.$copyText(this.token).then(() => {
        this.copied = true;
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId);
          this.copied = false;
        }, 800);
      });
    }
  }
};
</script>
