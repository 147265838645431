<template>
  <modal-dialog :show="show">
    <div v-if="!password">
      <h1 class="font-bold text-xl mb-4 text-center">Reset user password</h1>
      <div v-if="user" class="mb-4">
        <p>Id: {{ user.id }}</p>
        <p>Name: {{ user.name }}</p>
        <p>Email: {{ user.email }}</p>
      </div>
      <primary-button
        :loading="processing"
        @click="reset"
        class="w-full bg-red mb-4"
      >
        Reset Password
      </primary-button>
    </div>
    <div v-else class="text-center">
      <h1 class="font-bold text-xl mb-4 text-center">
        Share new temporary password
      </h1>
      <div
        class="relative text-sm bg-primary-100 border border-green-200 rounded py-2 px-4 mb-4"
      >
        {{ password }}
        <div
          class="absolute left-0 top-0 w-full font-bold text-white flex items-center justify-center bg-primary-500 transition-all duration-300"
          :class="copied ? 'h-full opacity-100' : 'h-0 opacity-0'"
        >
          Copied!
        </div>
      </div>
      <button
        @click="copy"
        class="border rounded px-4 py-2 mb-4 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
      >
        copy
      </button>
    </div>
    <div class="text-center">
      <button @click="$emit('close')">close</button>
    </div>
  </modal-dialog>
</template>

<script>
import { handleFailedResponse } from '@/core/helpers';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      copied: false,
      password: null
    };
  },
  methods: {
    async reset() {
      this.processing = true;
      try {
        const { data } = await this.axios.post(
          `/users/${this.user.id}/reset-password`
        );

        this.password = data.data.password;
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    },
    copy() {
      if (this.copied) {
        return;
      }
      this.$copyText(this.password).then(() => {
        this.copied = true;
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId);
          this.copied = false;
        }, 800);
      });
    }
  }
};
</script>
