<template>
  <div class="p-4 bg-white rounded shadow w-48">
    <div class="mb-4 text-center border-b border-gray-200 pb-2">
      {{ sensor.name }}
    </div>
    <div class="text-center border-b pb-2 border-gray-200 mb-2">
      <div class="mb-2">
        <div class="text-xs uppercase text-gray-600">Temp</div>
        <div class="text-xl">
          <span v-if="current.temperature">
            {{ parseFloat(current.temperature).toFixed(1) }}&deg;C
          </span>
          <span v-else class="text-gray-600">-</span>
        </div>
      </div>
      <div>
        <div class="text-xs uppercase text-gray-600">Humidity</div>
        <div class="text-xl">
          <span v-if="current.humidity"
            >{{ parseFloat(current.humidity).toFixed(1) }}%
          </span>
          <span v-else class="text-gray-600">-</span>
        </div>
      </div>
    </div>
    <div class="text-xs text-center text-gray-600">
      <div>last updated at:</div>
      <local-date :date="current.recorded_at"></local-date>
    </div>
    <div class="flex justify-center">
      <dropdown-menu v-model="show" interactive class="relative">
        <action-button icon="cog" @click.native.stop="show = true" />
        <div slot="dropdown">
          <action-button
            icon="edit"
            @click.native="$emit('edit')"
            label="Edit"
          />
          <action-button
            icon="sync-alt"
            @click.native="$emit('regenerate-token')"
            label="Regenerate Token"
          />
          <action-button
            icon="trash"
            @click.native="$emit('delete')"
            label="Delete"
          />
        </div>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';
import DropdownMenu from '@innologica/vue-dropdown-menu';
import LocalDate from '@/components/global/LocalDate';

export default {
  components: { LocalDate, ActionButton, DropdownMenu },
  props: {
    sensor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      current: {
        temperature: this.sensor.latest_recorded_temperature,
        humidity: this.sensor.latest_recorded_humidity,
        recorded_at: this.sensor.latest_recorded_at
      }
    };
  },
  mounted() {
    this.connect();
  },
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    connect() {
      this.$echo
        .channel(`dashboard.sensor.${this.sensor.id}`)
        .listen('.sensor.reading-updated', message => {
          this.current.temperature = message.reading.temperature;
          this.current.humidity = message.reading.humidity;
          this.current.recorded_at = message.reading.recorded_at;
        });
    },
    disconnect() {
      this.$echo.leaveChannel(`dashboard.sensor.${this.sensor.id}`);
    }
  }
};
</script>
