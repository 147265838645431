<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-baseline">
        <h2 class="text-lg font-bold mr-2">{{ location.name }}</h2>
        <div class="text-sm text-gray-700">({{ location.type }})</div>
      </div>

      <div>
        <action-button
          icon="trash"
          @click.native="$emit('delete')"
          class="mr-2"
        />
        <action-button
          icon="bell"
          @click.native="$emit('show-alerts', location)"
          class="mr-2"
        />
        <action-button
          icon="chart-line"
          @click.native="$emit('open-graph', location)"
          class="mr-2"
        />
        <action-button icon="edit" @click.native="$emit('edit')" />
        <action-button
          icon="plus"
          @click.native="$emit('add-sensor', location)"
        />
      </div>
    </div>

    <div class="flex flex-wrap gap-4">
      <sensor-card
        v-for="sensor in location.sensors"
        :key="sensor.id"
        :sensor="sensor"
        @edit="editSensor(location, sensor)"
        @delete="deleteSensor(location, sensor)"
        @regenerate-token="$emit('regenerate-token', { location, sensor })"
      />
    </div>
    <div v-if="location.sensors.length <= 0" class="text-sm text-gray-600">
      No sensors added yet.
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';
import SensorCard from './SensorCard';

export default {
  components: { SensorCard, ActionButton },
  props: {
    location: {
      type: Object,
      required: true
    },
    editSensor: {
      type: Function,
      required: true
    },
    deleteSensor: {
      type: Function,
      required: true
    }
  }
};
</script>
