<template>
  <modal-dialog :show="show" width="400" @close="close">
    <form v-if="!password" @submit.prevent="submit">
      <h1 class="text-center text-2xl font-bold mb-4">
        {{ user ? 'Edit' : 'Add new' }} user
      </h1>
      <input-field
        v-model="form.name"
        label="Name"
        class="w-full mb-4"
        :error="form.errors.get('name')"
      />
      <input-field
        v-model="form.email"
        type="email"
        label="Email"
        class="w-full mb-4"
        :error="form.errors.get('email')"
      />

      <label class="block mb-4">
        <input type="checkbox" v-model="form.super_admin" class="mr-2" />
        <span>Super admin</span>
      </label>

      <primary-button :loading="processing" class="w-full mb-4">
        Submit
      </primary-button>
    </form>
    <div v-else class="text-center">
      <h3 class="font-bold mb-4">Share temporary password</h3>
      <div
        class="relative text-sm bg-primary-100 border border-green-200 rounded py-2 px-4 mb-4"
      >
        {{ password }}
        <div
          class="absolute left-0 top-0 w-full font-bold text-white flex items-center justify-center bg-primary-500 transition-all duration-300"
          :class="copied ? 'h-full opacity-100' : 'h-0 opacity-0'"
        >
          Copied!
        </div>
      </div>
      <button
        @click="copy"
        class="border rounded px-4 py-2 mb-4 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
      >
        copy
      </button>
    </div>

    <div class="text-center">
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      password: null,
      copied: false,
      form: this.$form({
        name: null,
        email: null,
        super_admin: false
      })
    };
  },
  watch: {
    user(value) {
      if (value === null) {
        this.form.reset();
        this.password = null;
        return;
      }

      this.form.name = value.name;
      this.form.email = value.email;
      this.form.super_admin = value.is_super_admin;
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.password = null;
    },
    submit() {
      this.user ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        const { data } = await this.form.submit('post', '/users');

        this.password = data.password;
        this.$emit('create');
        this.form.reset();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit('patch', `/users/${this.user.id}`);

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    copy() {
      if (this.copied) {
        return;
      }
      this.$copyText(this.password).then(() => {
        this.copied = true;
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId);
          this.copied = false;
        }, 800);
      });
    }
  }
};
</script>
