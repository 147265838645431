<template>
  <tr>
    <td class="border px-4 py-2">{{ feedingBoxDimension.id }}</td>
    <td class="border px-4 py-2 text-left">{{ feedingBoxDimension.name }}</td>
    <td class="border px-4 py-2 text-right">
      {{ feedingBoxDimension.length }}
    </td>
    <td class="border px-4 py-2 text-right">
      {{ feedingBoxDimension.width }}
    </td>
    <td class="border px-4 py-2 text-right">
      {{ feedingBoxDimension.height || '-' }}
    </td>
    <td class="border px-4 py-2 text-right">
      {{ feedingBoxDimension.no_of_larvae }}
    </td>
    <td class="border px-4 py-2 text-right">
      <local-date :date="feedingBoxDimension.created_at" />
    </td>
    <td class="border px-4 py-2 text-right">
      <local-date :date="feedingBoxDimension.updated_at" />
    </td>
    <td class="border px-4 py-2 text-right">
      <action-button icon="edit" @click.native="$emit('edit')" />
      <action-button icon="trash" @click.native="$emit('delete')" />
    </td>
  </tr>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';
import LocalDate from '@/components/global/LocalDate';

export default {
  components: { LocalDate, ActionButton },
  props: {
    feedingBoxDimension: {
      type: Object,
      required: true
    }
  }
};
</script>
