<template>
  <fetch-json url="/users" :filter="filter">
    <template v-slot:header>
      <div class="flex justify-between">
        <h1 class="text-2xl font-bold">Users</h1>
        <primary-button @click="showUserDialog = true">
          + Add New
        </primary-button>
      </div>
    </template>

    <template v-slot:default="{ response: users, reload }">
      <table class="table-auto bg-white rounded-lg shadow-md w-full">
        <thead>
          <tr class="text-sm">
            <th class="text-left border px-4 py-2">ID</th>
            <th class="text-left border px-4 py-2">Name</th>
            <th class="text-left border px-4 py-2">Email</th>
            <th class="text-right border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <user-list-item
            v-for="user in users"
            :key="user.id"
            :user="user"
            class="mb-4"
            @edit="onEdit(user)"
            @delete="onDelete(user)"
            @reset-password="onResetPassword(user)"
          />
        </tbody>
      </table>

      <user-form
        :show="showUserDialog"
        :user="selectedUser"
        @close="onClose"
        @create="reload"
        @edit="reload"
      />

      <confirm-action-dialog
        :show="confirmDeleteDialog"
        type="delete"
        :resource="selectedUser"
        :action="deleteUser"
        @done="onConfirm(reload)"
        @close="onClose"
      >
        <template v-slot:title>Delete user</template>
        <p class="mb-4">Are you sure you want to delete selected user?</p>
        <div v-if="selectedUser">
          <p>ID: {{ selectedUser.id }}</p>
          <p>Name: {{ selectedUser.name }}</p>
          <p>Email: {{ selectedUser.email }}</p>
        </div>
      </confirm-action-dialog>

      <reset-password-dialog
        :show="showResetPasswordDialog"
        :user="selectedUser"
        @reset="onClose"
        @close="onClose"
      />
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import UserListItem from '@/components/user/UserListItem';
import UserForm from '@/components/user/UserForm';
import ResetPasswordDialog from '@/components/user/ResetPasswordDialog';

export default {
  components: { ResetPasswordDialog, UserForm, UserListItem, FetchJson },
  data() {
    return {
      showUserDialog: false,
      confirmDeleteDialog: false,
      showResetPasswordDialog: false,
      selectedUser: null,
      filter: {}
    };
  },
  methods: {
    onClose() {
      this.showUserDialog = false;
      this.confirmDeleteDialog = false;
      this.showResetPasswordDialog = false;
      this.selectedUser = null;
    },
    onEdit(user) {
      this.selectedUser = user;
      this.showUserDialog = true;
    },
    onDelete(user) {
      this.selectedUser = user;
      this.confirmDeleteDialog = true;
    },
    deleteUser(id) {
      return this.axios.delete(`/users/${id}`);
    },
    onConfirm(reload) {
      this.onClose();
      reload();
    },
    onResetPassword(user) {
      this.selectedUser = user;
      this.showResetPasswordDialog = true;
    }
  }
};
</script>
