<template>
  <modal-dialog :show="show" @close="close" width="600">
    <h1 class="text-center text-2xl font-bold mb-4">
      Hatching System
    </h1>
    <section-label label="Base config" />
    <div class="flex mb-4">
      <input-field
        class="w-1/2 mr-4"
        v-model="form.start_position"
        label="Cart start position (cm)"
        :error="form.errors.get('start_position')"
      />
    </div>
    <section-label label="Feeding boxes config" />
    <div class="flex mb-4">
      <input-field
        class="w-1/2 mr-4"
        v-model="form.no_of_feeding_boxes"
        label="No. of boxes"
        :error="form.errors.get('no_of_feeding_boxes')"
      />
      <input-field
        class="w-1/2"
        v-model="form.first_feeding_box_position"
        label="First feeding box position (cm)"
        :error="form.errors.get('first_feeding_box_position')"
      />
    </div>
    <div class="flex mb-4">
      <input-field
        class="w-1/2 mr-4"
        v-model="form.feeding_box_spacing"
        label="Box spacing (cm)"
        :error="form.errors.get('feeding_box_spacing')"
      />
      <div class="w-1/2"></div>
    </div>
    <div class="text-center">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    hatchingSystem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        time_interval: null,
        start_position: null,
        no_of_feeding_boxes: null,
        feeding_box_spacing: null,
        first_feeding_box_position: null
      })
    };
  },
  watch: {
    hatchingSystem(value) {
      if (value === null) {
        this.form.reset();
        return;
      }

      this.form.larvae_threshold = value.larvae_threshold;
      this.form.time_interval = value.time_interval;
      this.form.start_position = value.start_position;
      this.form.no_of_feeding_boxes = value.no_of_feeding_boxes;
      this.form.feeding_box_width = value.feeding_box_width;
      this.form.feeding_box_height = value.feeding_box_height;
      this.form.feeding_box_spacing = value.feeding_box_spacing;
      this.form.first_feeding_box_position = value.first_feeding_box_position;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.hatchingSystem ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        await this.form.submit('post', '/hatching-systems');

        this.$emit('create');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit(
          'patch',
          `/hatching-systems/${this.hatchingSystem.id}`
        );

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
