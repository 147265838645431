<template>
  <modal-dialog :show="show" @close="close">
    <h1 class="text-center text-2xl font-bold mb-4">
      Feeding Box Dimension
    </h1>
    <div class="flex justify-between mb-4">
      <input-field
        v-model="form.name"
        label="Name"
        class="w-1/2 mr-4"
        :error="form.errors.get('name')"
      />
      <input-field
        v-model="form.no_of_larvae"
        label="No of larvae"
        class="w-1/2"
        :error="form.errors.get('no_of_larvae')"
      />
    </div>
    <div class="flex justify-between mb-4">
      <input-field
        v-model="form.length"
        label="Length (cm)"
        class="w-1/2 mr-4"
        :error="form.errors.get('length')"
      />
      <input-field
        v-model="form.width"
        label="Width (cm)"
        class="w-1/2"
        :error="form.errors.get('width')"
      />
    </div>
    <div class="flex justify-between mb-4">
      <input-field
        v-model="form.height"
        label="Height (cm) (optional)"
        class="w-1/2 mr-4"
        :error="form.errors.get('height')"
      />
    </div>
    <div class="mb-4">
      <input-field
        v-model="form.description"
        label="Description (optional)"
        class="w-full"
        :error="form.errors.get('description')"
      />
    </div>
    <div class="text-center">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    feedingBoxDimension: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        name: null,
        length: null,
        width: null,
        height: null,
        no_of_larvae: null,
        description: null
      })
    };
  },
  watch: {
    feedingBoxDimension(value) {
      if (value === null) {
        this.form.reset();
        return;
      }

      this.form.name = value.name;
      this.form.length = value.length;
      this.form.width = value.width;
      this.form.height = value.height;
      this.form.no_of_larvae = value.no_of_larvae;
      this.form.description = value.description;
    },
    show(value) {
      if (!value) {
        this.form.errors.clear();
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.feedingBoxDimension ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        await this.form.submit('post', '/feeding-box-dimensions');

        this.$emit('create');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit(
          'patch',
          `/feeding-box-dimensions/${this.feedingBoxDimension.id}`
        );

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
