<template>
  <main>
    <section-title class="mb-4">General Settings</section-title>

    <div class="shadow bg-white p-8 rounded">
      <h3 class="font-bold text-lg mb-2">Password</h3>
      <button
        @click="showChangePasswordDialog = true"
        class="border rounded py-2 px-4 shadow"
      >
        Change password
      </button>
    </div>
    <modal-dialog :show="showChangePasswordDialog">
      <h1 class="font-bold mb-4">Change your password</h1>
      <change-password @done="passwordChanged" class="mb-4" />
      <div class="text-center">
        <button @click="showChangePasswordDialog = false">close</button>
      </div>
    </modal-dialog>
  </main>
</template>

<script>
import ChangePassword from '@/components/user/ChangePassword';

export default {
  components: { ChangePassword },
  data() {
    return {
      showChangePasswordDialog: false
    };
  },
  methods: {
    passwordChanged() {
      this.showChangePasswordDialog = false;
      this.$notify({
        group: 'app',
        type: 'success',
        text: 'Password updated.'
      });
    }
  }
};
</script>
