<template>
  <tr>
    <td class=" border px-4 py-2">{{ cart.id }}</td>
    <td class=" border px-4 py-2">{{ cart.name }}</td>
    <td class=" border px-4 py-2">{{ cart.description }}</td>
    <td class=" border px-4 py-2">{{ hatchingSystem }}</td>
    <td class=" border px-4 py-2 text-right">
      <local-date :date="cart.configured_at" />
    </td>
    <td class=" border px-4 py-2 text-right">
      <local-date :date="cart.created_at" />
    </td>
    <td class=" border px-4 py-2 text-right">
      <local-date :date="cart.updated_at" />
    </td>
    <td class=" border px-4 py-2 text-right">
      <action-button
        icon="trash"
        @click.native="$emit('delete')"
        class="mr-2"
      />
      <action-button
        icon="sync-alt"
        @click.native="$emit('regenerate-token')"
        class="mr-2"
      />
      <action-button icon="edit" @click.native="$emit('edit')" />
    </td>
  </tr>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';
import LocalDate from '@/components/global/LocalDate';

export default {
  components: { LocalDate, ActionButton },
  props: {
    cart: {
      type: Object,
      required: true
    }
  },
  computed: {
    hatchingSystem() {
      if (!this.cart.hatching_system) {
        return '-';
      }

      return `${this.cart.hatching_system.id}`;
    }
  }
};
</script>
