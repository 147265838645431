<template>
  <modal-dialog :show="show" @close="close" width="600">
    <h1 class="text-center text-2xl font-bold mb-4">
      Location
    </h1>
    <div class="flex mb-6">
      <input-field
        v-model="form.name"
        label="Name"
        class="flex-1 mr-8"
        :error="form.errors.get('name')"
      />
      <select-field
        v-model="form.type"
        option-label="label"
        :reduce="option => option.name"
        :error="form.errors.get('type')"
        label="Select type"
        :fixed-options="types"
        class="flex-1"
      />
    </div>
    <div class="flex mb-6">
      <input-field
        v-model="form.min_temperature"
        label="Min temperature"
        type="number"
        class="flex-1 mr-8"
        :error="form.errors.get('min_temperature')"
      />
      <input-field
        v-model="form.max_temperature"
        label="Max temperature"
        type="number"
        class="flex-1"
        :error="form.errors.get('max_temperature')"
      />
    </div>
    <div class="flex mb-6">
      <input-field
        v-model="form.min_humidity"
        label="Min humidity"
        type="number"
        class="flex-1 mr-8"
        :error="form.errors.get('min_humidity')"
      />
      <input-field
        v-model="form.max_humidity"
        label="Max humidity"
        type="number"
        class="flex-1"
        :error="form.errors.get('max_humidity')"
      />
    </div>
    <div class="text-center">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    location: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      types: [
        { name: 'inside', label: 'Inside' },
        { name: 'outside', label: 'Outside' }
      ],
      form: this.$form({
        name: null,
        type: null,
        min_temperature: null,
        max_temperature: null,
        min_humidity: null,
        max_humidity: null
      })
    };
  },
  watch: {
    location: {
      immediate: true,
      handler(value) {
        if (value === null) {
          this.form.reset();
          return;
        }

        this.form.name = value.name;
        this.form.type = value.type;
        this.form.min_temperature = value.min_temperature;
        this.form.max_temperature = value.max_temperature;
        this.form.min_humidity = value.min_humidity;
        this.form.max_humidity = value.max_humidity;
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.location ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        const { data: location } = await this.form.submit('post', '/locations');

        this.$emit('create', { location });
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit('put', `/locations/${this.location.id}`);

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
