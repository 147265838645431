<template>
  <modal-dialog :show="show" width="400" @close="close">
    <div v-if="hatchingSystem">
      <h1 class="font-bold mb-4 text-center">
        Assign cart to hatching system #{{ hatchingSystem.id }}
      </h1>

      <div v-if="hatchingSystem.cart" class="text-center mb-4">
        <p>Currently assigned cart</p>
        <p class="font-bold">
          <span>#{{ hatchingSystem.cart.id }}</span>
          <span v-if="hatchingSystem.cart.name">
            ({{ hatchingSystem.cart.name }})
          </span>
        </p>
      </div>
    </div>

    <p class="text-center text-sm text-gray-500 mb-4">
      Select new cart to update pairing
    </p>
    <select-field
      v-model="form.cart"
      :reduce="option => option.id"
      :error="form.errors.get('cart')"
      :get-option-label="getCartLabel"
      label="Select cart"
      url="/carts"
      class="w-full h-full mb-4"
    />

    <primary-button @click="assign" :loading="processing" class="w-full mb-4">
      Assign Cart
    </primary-button>
    <button @click="$emit('close')" class="w-full">close</button>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    hatchingSystem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        cart: null
      })
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async assign() {
      this.processing = true;
      try {
        await this.form.submit(
          'post',
          `/hatching-systems/${this.hatchingSystem.id}`
        );

        this.$emit('assign');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    getCartLabel(option) {
      let label = option.id;

      if (option.name) {
        label += ` - (${option.name})`;
      }
      return label;
    }
  }
};
</script>
