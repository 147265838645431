<template>
  <modal-dialog :show="show" @close="onClose">
    <div v-if="!token" class="mb-2">
      <h1 class="font-bold text-lg mb-2">
        Regenerate auth token
      </h1>
      <p class="mb-2">Are you sure you want to regenerate auth cart token?</p>
      <p v-if="cart">Cart id: {{ cart.id }}</p>
      <div class="flex justify-end mt-4">
        <primary-button
          :loading="processing"
          class="w-full bg-red-600 hover:bg-red-700 active:bg-red-500 px-4 w-32"
          @click="regenerateToken"
        >
          Regenerate Token
        </primary-button>
      </div>
    </div>
    <div v-else class="text-center mb-2">
      <h3 v-if="cart" class="font-bold mb-4">Configure Cart #{{ cart.id }}</h3>
      <div
        class="relative text-sm bg-primary-100 border border-green-200 rounded py-2 px-4 mb-4"
      >
        {{ token }}
        <div
          class="absolute left-0 top-0 w-full font-bold text-white flex items-center justify-center bg-primary-500 transition-all duration-300"
          :class="copied ? 'h-full opacity-100' : 'h-0 opacity-0'"
        >
          Copied!
        </div>
      </div>
      <button
        @click="copy"
        class="border rounded px-4 py-2 mb-4 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
      >
        copy
      </button>
      <br />
    </div>
    <div class="text-center">
      <button @click="onClose">close</button>
    </div>
  </modal-dialog>
</template>

<script>
import { handleFailedResponse } from '@/core/helpers';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    cart: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      token: null,
      copied: false
    };
  },
  methods: {
    onClose() {
      this.token = null;
      this.$emit('close');
    },
    async regenerateToken() {
      this.processing = true;
      try {
        const { data } = await this.axios.post(
          `/carts/${this.cart.id}/regenerate-token`
        );

        this.token = data.data.token;
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    },
    copy() {
      if (this.copied) {
        return;
      }
      this.$copyText(this.token).then(() => {
        this.copied = true;
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId);
          this.copied = false;
        }, 800);
      });
    }
  }
};
</script>
