<template>
  <tr>
    <td class="p-2">{{ user.name }}</td>
    <td class="p-2">{{ user.email }}</td>
    <td v-if="processing" class="p-2 text-right">
      <fa-icon :icon="['fas', 'spinner-third']" class="text-blue-500 fa-spin" />
    </td>
    <td v-else class="p-2 text-right">
      <button v-if="isLocationAssigned" @click="dettach">
        <fa-icon :icon="['fas', 'check-square']" class="text-green-500" />
      </button>
      <button v-else @click="attach">
        <fa-icon :icon="['fas', 'square']" class="text-gray-400" />
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    location: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      isLocationAssigned: this.user.locations
        .map(location => location.id)
        .includes(this.location.id)
    };
  },
  methods: {
    async attach() {
      if (this.processing) return;
      try {
        this.processing = true;
        await this.axios.post(
          `/locations/${this.location.id}/users/${this.user.id}`
        );
        this.isLocationAssigned = true;
        this.$notify({
          group: 'app',
          type: 'success',
          text: `Assigned ${this.user.name} to ${this.location.name} alerts`
        });
      } catch (e) {
        this.$notify({
          group: 'app',
          type: 'error',
          text: `Failed to assign ${this.user.name} to ${this.location.name}`
        });
      } finally {
        this.processing = false;
      }
    },
    async dettach() {
      if (this.processing) return;
      try {
        this.processing = true;
        await this.axios.delete(
          `/locations/${this.location.id}/users/${this.user.id}`
        );
        this.isLocationAssigned = false;
        this.$notify({
          group: 'app',
          type: 'success',
          text: `Detached ${this.user.name} from ${this.location.name} alerts`
        });
      } catch (e) {
        this.$notify({
          group: 'app',
          type: 'error',
          text: `Failed to assign ${this.user.name} to ${this.location.name}`
        });
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
