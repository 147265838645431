<template>
  <tr>
    <td class="border px-4 py-2">{{ user.id }}</td>
    <td class="border px-4 py-2">
      <span class="mr-4">{{ user.name }}</span>
      <span
        v-if="user.is_super_admin"
        class="px-2 py-1 text-xs bg-green-500 text-white rounded font-bold"
      >
        super admin
      </span>
    </td>
    <td class="border px-4 py-2">{{ user.email }}</td>
    <td class="border px-4 py-2 text-right">
      <action-button
        v-if="!isCurrentlyLoggedIn"
        icon="trash"
        @click.native="$emit('delete')"
        class="mr-4"
      />
      <action-button
        icon="sync-alt"
        @click.native="$emit('reset-password')"
        class="mr-4"
      />
      <action-button icon="edit" @click.native="$emit('edit')" />
    </td>
  </tr>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';

export default {
  components: { ActionButton },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCurrentlyLoggedIn() {
      return this.user.id === this.$store.state.auth.user.id;
    }
  }
};
</script>
