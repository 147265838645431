<template>
  <fetch-json url="/carts">
    <template v-slot:header>
      <div class="flex justify-between mb-4">
        <section-title>Carts</section-title>
        <primary-button @click="showForm = true">
          + Add New
        </primary-button>
      </div>
    </template>
    <template slot-scope="{ response: carts, reload, deleteResource }">
      <table class="table-auto bg-white w-full rounded-lg shadow-md">
        <thead>
          <tr>
            <th class="text-left border px-4 py-2">Id</th>
            <th class="text-left border px-4 py-2">Name</th>
            <th class="text-left border px-4 py-2">Description</th>
            <th class="text-left border px-4 py-2">System</th>
            <th class="text-right border px-4 py-2">Paired At</th>
            <th class="text-right border px-4 py-2">Created At</th>
            <th class="text-right border px-4 py-2">Updated At</th>
            <th class="text-right border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <cart-list-item
            v-for="cart in carts"
            :key="cart.id"
            :cart="cart"
            class="mb-4"
            @edit="onEdit(cart)"
            @delete="onDelete(cart)"
            @regenerate-token="onRegenerateToken(cart)"
          />
        </tbody>
      </table>

      <cart-form-container
        :show="showForm"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :cart="selectedCart"
      />

      <confirm-action-dialog
        :show="showDeleteDialog"
        type="delete"
        :resource="selectedCart"
        :action="deleteResource"
        @close="onClose"
        @done="reload"
      >
        <template slot="title">
          Delete Cart
          <span v-if="selectedCart"> &nbsp; #{{ selectedCart.id }} </span>
          ?
        </template>
        <p>Are you sure you want to delete selected cart?</p>
      </confirm-action-dialog>

      <regenerate-cart-token
        :show="showRegenerateTokenDialog"
        :cart="selectedCart"
        @close="onClose"
      />
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import CartListItem from '@/components/carts/CartListItem';
import CartFormContainer from '@/components/carts/CartFormContainer';
import RegenerateCartToken from '@/components/carts/RegenerateCartToken';

export default {
  name: 'FeedingBoxDimensions',
  components: {
    RegenerateCartToken,
    CartFormContainer,
    CartListItem,
    FetchJson
  },
  data() {
    return {
      showForm: false,
      selectedCart: null,
      showDeleteDialog: false,
      showRegenerateTokenDialog: false
    };
  },
  methods: {
    onClose() {
      this.selectedCart = null;
      this.showForm = false;
      this.showDeleteDialog = false;
      this.showRegenerateTokenDialog = false;
    },
    onEdit(cart) {
      this.selectedCart = cart;
      this.showForm = true;
    },
    onDelete(cart) {
      this.selectedCart = cart;
      this.showDeleteDialog = true;
    },
    onRegenerateToken(cart) {
      this.selectedCart = cart;
      this.showRegenerateTokenDialog = true;
    }
  }
};
</script>
